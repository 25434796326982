import React from 'react';
import PageWrapper from "../components/PageWrapper";
import Header from "../components/Header";
import Footer from "../components/Footer";
// import "bootstrap/dist/css/bootstrap-grid.css"
import PayForm from '../components/VentureCapital/PayForm'
import { Helmet } from "react-helmet"
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';


function payment({location}) {

  const stripePromise = loadStripe('pk_live_51JayKeJPNl8DCuX4tlQpIvywSMk6P3Xh5lTL9xXgGS09ZcKLGhQXmvei4EKcnjQGYICzRlZx1PNFtVfIDLIFSYhv00wTWHw8Fg');
  let target = '/'
  let title = ''

  if(location.state){
    target = location.state.target
    title = location.state.title
  }

  return (
    <Elements stripe={stripePromise}>
 <PageWrapper footerDark>
    <Header />
    <section>
       <Helmet>
        <html lang="en" />
        <title>Venture Capital London, UK Full Database - VCTracker</title>
        <meta name="title" content="Venture Capital London, UK Full Database - VCTracker"></meta>
        <meta name="description" content="Get the ful list is venture capital firms in the UK. The VC database provides names, websites, partners, linkedin profiles, twitter accounts and more.."/>
     
      </Helmet>
      {/* <HeaderPay home={target || '/'}/> */}
      <PayForm currency='gbp' title={title} target={target}/>
      {/* <Footer home={target  || '/'} contact={`${target || '/'}contact/`} termsConditions={`${target || '/'}terms-conditions/`} privacyPolicy={`${target || '/'}privacy-policy/`}/> */}
    </section>
  
    <Footer/>
      </PageWrapper>
      </Elements>
  )
}


export default payment
