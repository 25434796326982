import React, { useMemo, useState, useEffect } from 'react';
import { fetchFromAPI } from '../../utils/helpers';
import { CardNumberElement, CardCvcElement, CardExpiryElement, useStripe, useElements } from '@stripe/react-stripe-js';
import * as Bs from "react-bootstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock, faCircleNotch, faSpin } from '@fortawesome/free-solid-svg-icons'
// import 'font-awesome/css/font-awesome.min.css';
import { navigate } from 'gatsby'

import './styles2.css'

function PayButton(props) {
  
  
  const stripe = useStripe();
  const elements = useElements();
  const [currency, setCurrency] = useState();
  const [spinner, setSpinner] = useState('spinnerFalse')

  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    address: '',
    city: '',
    postcode: '',
  })

  const [country, setCountry] = useState('GB')

  const [paymentIntent, setPaymentIntent] = useState();

 
  const useOptions = () => {
    
    const options = useMemo(
      () => ({
        hideIcon: true,
        // placeholder: 'test',
        style: {
          base: {
            fontSize: '18px',
            color: "#424770",
            letterSpacing: "0.025em",
            fontFamily: 'Montserrat',
            "::placeholder": {
              color: "#aab7c4",
              fontFamily: 'arial',
              fontSize: 18,
            },
            
          },
          invalid: {
            color: "#9e2146"
          }
        }
      }),
      []
    );
  
    return options;
  };

  const useOptions1 = () => {
    
    const options1 = useMemo(
      () => ({
        showIcon: true,
        placeholder: '•••• •••• •••• ••••',
        style: {
          base: {
            fontSize: '18px',
            color: "#424770",
            letterSpacing: "0.025em",
            fontFamily: 'Montserrat',
            "::placeholder": {
              color: "#aab7c4",
              fontFamily: 'Montserrat',
              fontSize: 25,
            },
            
          },
          invalid: {
            color: "#9e2146"
          }
        }
      }),
      []
    );
  
    return options1;
  };


  const options = useOptions();
  const options1 = useOptions1();

  // Create a payment intent on the server
  const createPaymentIntent = async (event) => {

    // Clamp amount to Stripe min/max
    // const validAmount = Math.min(Math.max(amount, 50), 9999999);
    // setAmount(validAmount);
    // console.log('amount', amount)
    setCurrency("gbp")
    
    // Make the API Request
    const pi = await fetchFromAPI('payments', { body: 
      {
      amount: 31,
      currency: currency,
      }
      });
    setPaymentIntent(pi);
   
  };

  useEffect(()=> {
    createPaymentIntent()
  }, [])

  // Handle InputChange for name an email
  function handleInputChange(e) {
    e.persist();
    // setErrorMessage('')
    setFormValues(currentValues => ({
      ...currentValues,
      [e.target.name]: e.target.value
    }))
  }

  // Handle selection of country drop down
  const handleChange = e => {
    e.preventDefault();
    setCountry(e.target.value)
  }
  
  console.log({
    email: formValues.email,
    name: formValues.name,
    address: {
      city: formValues.city,
      country: country,
      line1: formValues.address,
      postal_code: formValues.postal_code,
    }
  })
  // Handle the submission of card details
  const handleSubmit = async (event) => {
    event.preventDefault();

    const cardElement = elements.getElement(CardNumberElement);

    setSpinner('spinnerTrue')
    // Confirm Card Payment
    const {
      paymentIntent: updatedPaymentIntent,
      error,
    } = await stripe.confirmCardPayment(paymentIntent.client_secret, {
      payment_method: { 
        card: cardElement,
        billing_details: {
          email: formValues.email,
          name: formValues.name,
          address: {
            city: formValues.city,
            country: country,
            line1: formValues.address,
            postal_code: formValues.postal_code,
          }
        }
      },
      receipt_email: formValues.email,
    });

    if (error) {
      console.error(error);
      error.payment_intent && setPaymentIntent(error.payment_intent);
    } else {
      setPaymentIntent(updatedPaymentIntent);
    }
  };

  // useEffect(()=> {
  //   if(spinner === 'spinnerTrue') {

  //   }
  // }, [])

  return (
    <>
    <section>
   
{/* <hr style={{marginBottom: 0}}/> */}

      <Bs.Container fluid id='fifty' style={{marginBottom: -100, marginTop: 76}}>

      
<Bs.Row style={{maxWidth: 1280, margin: 'auto'}}>
<Bs.Col style={{height: '100vh', minHeight: 900 }} sm={6}>
  <section style={{marginTop: 50, marginLeft: 30, marginRight: 50}}>

  <h3 style={{fontWeight: 700, marginTop: 40}}>Order summary</h3>
    


<Bs.Row style={{maxWidth: 600, marginTop: 30}}>
  <Bs.Col sm={3}><img className='logos' variant="top" src={`/venture-capital/vcdatabase.png`} alt='venture capital database' style={{maxWidth: 150}}></img></Bs.Col>
  <Bs.Col sm={9}>
    <h3 style={{fontWeight: 700, marginTop: 20, marginBottom: 20, marginLeft: 20}}>{props.title || 'Venture Capital UK'} <br></br> <span style={{fontWeight: 300}}>2021 edition</span></h3>
<div>
    <ul style={{marginLeft: 3}}>
      <li>114 venture investors in one spreadsheet</li>
      <li>34 startup accelerators </li>
      <li>39 business angel syndicates</li>
      <li>57 active business angels</li>
      <li>VCs, 187 weBsites, partners, 1479 linkedin profiles, 339 twitter accounts...</li>
      <li>No additional software required</li>
      <li>Link to your spreadsheet is sent by email</li>
      <li>Start engaging in minutes</li>
    </ul>
    </div>
    </Bs.Col>
</Bs.Row>

<Bs.Row style={{marginTop: 40}}>
          <Bs.Col sm={9}><h6 style={{}}>Subtotal</h6></Bs.Col>
          <Bs.Col><h6 style={{fontWeight: 700, float: 'right'}}>£39.00</h6></Bs.Col>
        </Bs.Row>
        <Bs.Row>
          <Bs.Col><h6 style={{}}>Shipping</h6></Bs.Col>
          <Bs.Col><h6 style={{float: 'right'}}>Free</h6></Bs.Col>
        </Bs.Row>
        <hr></hr>
        <Bs.Row>
          <Bs.Col><h6 style={{fontWeight: 700}}>Total</h6></Bs.Col>
          <Bs.Col><h6 style={{fontWeight: 700, float: 'right'}}>£39.00</h6></Bs.Col>
        </Bs.Row>




  </section>

</Bs.Col>
  <Bs.Col sm={6}> 
    
    <form style={{marginTop: 50, maxWidth: 500, marginLeft: 30}} onSubmit={handleSubmit} >
    {/* hidden={!paymentIntent || paymentIntent.status === 'succeeded' } */}
        <h3 style={{fontWeight: 700}}>Billing details</h3>
        <Bs.Row>
        <Bs.Col sm={6}>
      <label>
        Name on card*
        <Bs.Form.Control type='text' placeholder='' onChange={handleInputChange} value={formValues.name} name="name" style={{fontSize: 18}}/>
      </label></Bs.Col>
      <Bs.Col sm={6}><label>
        Email Address*
        <Bs.Form.Control type='email'  placeholder='' onChange={handleInputChange} value={formValues.email} name="email" style={{fontSize: 18}}/>
      </label></Bs.Col>
      </Bs.Row>
      <Bs.Row>
        <Bs.Col sm={6}><label>
        Street Address*
        <Bs.Form.Control type='text'  placeholder='' onChange={handleInputChange} value={formValues.address} name="address" style={{fontSize: 18}}/>
      </label></Bs.Col>
        <Bs.Col sm={6}> <label>
        Town / City*
        <Bs.Form.Control type='text'  placeholder='' onChange={handleInputChange} value={formValues.city} name="city" style={{fontSize: 18}}/>
      </label></Bs.Col>
      </Bs.Row>
      <Bs.Row>
      <Bs.Col sm={6}>
      <label>
        Postcode / Zip Code*
        <Bs.Form.Control type='text'  placeholder='' onChange={handleInputChange} value={formValues.postcode} name="postcode" style={{fontSize: 18}}/>
      </label>
      </Bs.Col>
      <Bs.Col sm={6}> 
      <label>
        Country*
          <select className="form-control" id="country" onChange={(e) => setCountry(e.target.value)} defaultValue={'GB'} style={{fontSize: 18, marginTop: 10}}>
          <option value="" disabled="" hidden=""></option>
  <option value="AF">Afghanistan</option>
  <option value="AX">Åland Islands</option>
  <option value="AL">Albania</option>
  <option value="DZ">Algeria</option>
  0<option value="AD">Andorra</option>
  <option value="AO">Angola</option>
  <option value="AI">Anguilla</option>
  <option value="AQ">Antarctica</option>
  <option value="AG">Antigua &amp; Barbuda</option>
  <option value="AR">Argentina</option>
  <option value="AM">Armenia</option>
  <option value="AW">Aruba</option>
  <option value="AC">Ascension Island</option>
  <option value="AU">Australia</option>
  <option value="AT">Austria</option>
  <option value="AZ">Azerbaijan</option>
  <option value="Bs">Bahamas</option>
  <option value="BH">Bahrain</option>
  <option value="BD">Bangladesh</option>
  <option value="BB">Barbados</option>
  <option value="BY">Belarus</option>
  <option value="BE">Belgium</option>
  <option value="BZ">Belize</option>
  <option value="BJ">Benin</option>
  <option value="BM">Bermuda</option>
  <option value="BT">Bhutan</option>
  <option value="BO">Bolivia</option>
  <option value="BA">Bosnia &amp; Herzegovina</option>
  <option value="BW">Botswana</option>
  <option value="BV">Bouvet Island</option>
  <option value="BR">Brazil</option>
  <option value="IO">British Indian Ocean Territory</option>
  <option value="VG">British Virgin Islands</option>
  <option value="BN">Brunei</option>
  <option value="BG">Bulgaria</option>
  <option value="BF">Burkina Faso</option>
  <option value="BI">Burundi</option>
  <option value="KH">Cambodia</option>
  <option value="CM">Cameroon</option>
  <option value="CA">Canada</option>
  <option value="CV">Cape Verde</option>
  <option value="BQ">Caribbean Netherlands</option>
  <option value="KY">Cayman Islands</option>
  <option value="CF">Central African Republic</option>
  <option value="TD">Chad</option>
  <option value="CL">Chile</option>
  <option value="CN">China</option>
  <option value="CO">Colombia</option>
  <option value="KM">Comoros</option>
  <option value="CG">Congo - Brazzaville</option>
  <option value="CD">Congo - Kinshasa</option>
  <option value="CK">Cook Islands</option>
  <option value="CR">Costa Rica</option>
  <option value="CI">Côte d’Ivoire</option>
  <option value="HR">Croatia</option>
  <option value="CW">Curaçao</option>
  <option value="CY">Cyprus</option>
  <option value="CZ">Czechia</option>
  <option value="DK">Denmark</option>
  <option value="DJ">Djibouti</option>
  <option value="DM">Dominica</option>
  <option value="DO">Dominican Republic</option>
  <option value="EC">Ecuador</option>
  <option value="EG">Egypt</option>
  <option value="SV">El Salvador</option>
  <option value="GQ">Equatorial Guinea</option>
  <option value="ER">Eritrea</option>
  <option value="EE">Estonia</option>
  <option value="SZ">Eswatini</option>
  <option value="ET">Ethiopia</option>
  <option value="FK">Falkland Islands</option>
  <option value="FO">Faroe Islands</option>
  <option value="FJ">Fiji</option>
  <option value="FI">Finland</option>
  <option value="FR">France</option>
  <option value="GF">French Guiana</option>
  <option value="PF">French Polynesia</option>
  <option value="TF">French Southern Territories</option>
  <option value="GA">Gabon</option>
  <option value="GM">Gambia</option>
  <option value="GE">Georgia</option>
  <option value="DE">Germany</option>
  <option value="GH">Ghana</option>
  <option value="GI">Gibraltar</option>
  <option value="GR">Greece</option>
  <option value="GL">Greenland</option>
  <option value="GD">Grenada</option>
  <option value="GP">Guadeloupe</option>
  <option value="GU">Guam</option>
  <option value="GT">Guatemala</option>
  <option value="GG">Guernsey</option>
  <option value="GN">Guinea</option>
  <option value="GW">Guinea-Bissau</option>
  <option value="GY">Guyana</option>
  <option value="HT">Haiti</option>
  <option value="HN">Honduras</option>
  <option value="HK">Hong Kong SAR China</option>
  <option value="HU">Hungary</option>
  <option value="IS">Iceland</option>
  <option value="IN">India</option>
  <option value="ID">Indonesia</option>
  <option value="IQ">Iraq</option>
  <option value="IE">Ireland</option>
  <option value="IM">Isle of Man</option>
  <option value="IL">Israel</option>
  <option value="IT">Italy</option>
  <option value="JM">Jamaica</option>
  <option value="JP">Japan</option>
  <option value="JE">Jersey</option>
  <option value="JO">Jordan</option>
  <option value="KZ">Kazakhstan</option>
  <option value="KE">Kenya</option>
  <option value="KI">Kiribati</option>
  <option value="XK">Kosovo</option>
  <option value="KW">Kuwait</option>
  <option value="KG">Kyrgyzstan</option>
  <option value="LA">Laos</option>
  <option value="LV">Latvia</option>
  <option value="LB">Lebanon</option>
  <option value="LS">Lesotho</option>
  <option value="LR">Liberia</option>
  <option value="LY">Libya</option>
  <option value="LI">Liechtenstein</option>
  <option value="LT">Lithuania</option>
  <option value="LU">Luxembourg</option>
  <option value="MO">Macao SAR China</option>
  <option value="MG">Madagascar</option>
  <option value="MW">Malawi</option>
  <option value="MY">Malaysia</option>
  <option value="MV">Maldives</option>
  <option value="ML">Mali</option>
  <option value="MT">Malta</option>
  <option value="MQ">Martinique</option>
  <option value="MR">Mauritania</option>
  <option value="MU">Mauritius</option>
  <option value="YT">Mayotte</option>
  <option value="MX">Mexico</option>
  <option value="MD">Moldova</option>
  <option value="MC">Monaco</option>
  <option value="MN">Mongolia</option>
  <option value="ME">Montenegro</option>
  <option value="MS">Montserrat</option>
  <option value="MA">Morocco</option>
  <option value="MZ">Mozambique</option>
  <option value="MM">Myanmar (Burma)</option>
  <option value="NA">Namibia</option>
  <option value="NR">Nauru</option>
  <option value="NP">Nepal</option>
  <option value="NL">Netherlands</option>
  <option value="NC">New Caledonia</option>
  <option value="NZ">New Zealand</option>
  <option value="NI">Nicaragua</option>
  <option value="NE">Niger</option>
  <option value="NG">Nigeria</option>
  <option value="NU">Niue</option>
  <option value="MK">North Macedonia</option>
  <option value="NO">Norway</option>
  <option value="OM">Oman</option>
  <option value="PK">Pakistan</option>
  <option value="PS">Palestinian Territories</option>
  <option value="PA">Panama</option>
  <option value="PG">Papua New Guinea</option>
  <option value="PY">Paraguay</option>
  <option value="PE">Peru</option>
  <option value="PH">Philippines</option>
  <option value="PN">Pitcairn Islands</option>
  <option value="PL">Poland</option>
  <option value="PT">Portugal</option>
  <option value="PR">Puerto Rico</option>
  <option value="QA">Qatar</option>
  <option value="RE">Réunion</option>
  <option value="RO">Romania</option>
  <option value="RU">Russia</option>
  <option value="RW">Rwanda</option>
  <option value="WS">Samoa</option>
  <option value="SM">San Marino</option>
  <option value="ST">São Tomé &amp; Príncipe</option>
  <option value="SA">Saudi Arabia</option>
  <option value="SN">Senegal</option>
  <option value="RS">Serbia</option>
  <option value="SC">Seychelles</option>
  <option value="SL">Sierra Leone</option>
  <option value="SG">Singapore</option>
  <option value="SX">Sint Maarten</option>
  <option value="SK">Slovakia</option>
  <option value="SI">Slovenia</option>
  <option value="SB">Solomon Islands</option>
  <option value="SO">Somalia</option>
  <option value="ZA">South Africa</option>
  <option value="GS">South Georgia &amp; South Sandwich Islands</option>
  <option value="KR">South Korea</option>
  <option value="SS">South Sudan</option>
  <option value="ES">Spain</option>
  <option value="LK">Sri Lanka</option>
  <option value="BL">St Barthélemy</option>
  <option value="SH">St Helena</option>
  <option value="KN">St Kitts &amp; Nevis</option>
  <option value="LC">St Lucia</option>
  <option value="MF">St Martin</option>
  <option value="PM">St Pierre &amp; Miquelon</option>
  <option value="VC">St Vincent &amp; Grenadines</option>
  <option value="SR">Suriname</option>
  <option value="SJ">Svalbard &amp; Jan Mayen</option>
  <option value="SE">Sweden</option>
  <option value="CH">Switzerland</option>
  <option value="TW">Taiwan</option>
  <option value="TJ">Tajikistan</option>
  <option value="TZ">Tanzania</option>
  <option value="TH">Thailand</option>
  <option value="TL">Timor-Leste</option>
  <option value="TG">Togo</option>
  <option value="TK">Tokelau</option>
  <option value="TO">Tonga</option>
  <option value="TT">Trinidad &amp; Tobago</option>
  <option value="TA">Tristan da Cunha</option>
  <option value="TN">Tunisia</option>
  <option value="TR">Turkey</option>
  <option value="TM">Turkmenistan</option>
  <option value="TC">Turks &amp; Caicos Islands</option>
  <option value="TV">Tuvalu</option>
  <option value="UG">Uganda</option>
  <option value="UA">Ukraine</option>
  <option value="AE">United Arab Emirates</option>
  <option value="GB">United Kingdom</option>
  <option value="US">United States</option>
  <option value="UY">Uruguay</option>
  <option value="UZ">Uzbekistan</option>
  <option value="VU">Vanuatu</option>
  <option value="VA">Vatican City</option>
  <option value="VE">Venezuela</option>
  <option value="VN">Vietnam</option>
  <option value="WF">Wallis &amp; Futuna</option>
  <option value="EH">Western Sahara</option>
  <option value="YE">Yemen</option>
  <option value="ZM">Zambia</option>
  <option value="ZW">Zimbabwe</option>

          </select>
        </label>
      </Bs.Col>

      </Bs.Row>
    
    
      
       
        <h3 style={{fontWeight: 700, marginTop: 30}}>Card details</h3>
      <label>
        Card number*
      
        <CardNumberElement class="form-control"
          options={options1}
          onReady={() => {
            console.log("CardNumberElement [ready]");
          }}
          onChange={event => {
            console.log("CardNumberElement [change]", event);
          }}
          onBlur={() => {
            console.log("CardNumberElement [blur]");
          }}
          onFocus={() => {
            console.log("CardNumberElement [focus]");
          }}
          
        />
      </label>
      <Bs.Row>
        <Bs.Col><label>
        Expiration date*
        <CardExpiryElement style={{fontSize: 18}}
          options={options}
          onReady={() => {
            console.log("CardNumberElement [ready]");
          }}
          onChange={event => {
            console.log("CardNumberElement [change]", event);
          }}
          onBlur={() => {
            console.log("CardNumberElement [blur]");
          }}
          onFocus={() => {
            console.log("CardNumberElement [focus]");
          }}
        />
      </label></Bs.Col>
        <Bs.Col>   <label>
        CVC*
        <CardCvcElement style={{fontSize: 18}}
          options={options}
          onReady={() => {
            console.log("CardNumberElement [ready]");
          }}
          onChange={event => {
            console.log("CardNumberElement [change]", event);
          }}
          onBlur={() => {
            console.log("CardNumberElement [blur]");
          }}
          onFocus={() => {
            console.log("CardNumberElement [focus]");
          }}
        />
      </label></Bs.Col>
      </Bs.Row>
      <button type="submit" disabled={!stripe}>
      <Bs.Row>Confirm your order <FontAwesomeIcon /></Bs.Row>
       {/* <Bs.Row>Confirm your order <FontAwesomeIcon className={'fa-spin'} icon={faCircleNotch} id={spinner}/></Bs.Row> */}
      </button>
      <p style={{marginTop: 20}}><FontAwesomeIcon icon={faLock} style={{color:'#c7c7c7'}}/><span style={{marginLeft: 10}}>Your purchase is secure</span></p>

      </form></Bs.Col>
 
</Bs.Row>
      


   
      <hr style={{}}/>
      </Bs.Container>
      <PaymentIntentData data={paymentIntent} target={props.target} />
      {/* <ThankYou data={paymentIntent}/> */}
      </section>
    </>
  );
}

// function navigator(props){

//   navigate('/thank-you-EB3U/',{state: {target: props.target,}}
//   )

//   return(
//     {navigate()}
//   )
// }


function PaymentIntentData(props) {

  
  if (props.data) {
    const { status } = props.data;

    
    return (
      <>
      {/* <span className={
            'badge ' + ( status === 'succeeded' ? 'badge-success' : 'badge-secondary')
          }>
          {status}
        </span> */}
        {status === 'succeeded' ? navigate('/venture-capital/thank-you-EB3U/', {state:{target: props.target}}) : console.log('not succeeded yet')}
     
    
      </>
    );
  } else {
    return null;
  }
}

export default PayButton;